import { Box, Container, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StandardCSSProperties, SxProps } from '@mui/system';
import React from 'react';
import { colors } from '../Theme';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(5)
	},
	header: {
		textTransform: 'uppercase',
		marginBottom: theme.spacing(5)
	},
	shaded: {
		backgroundColor: theme.palette.text.secondary
	},
	anchor: {
		'&:target': {
			display: 'block',
			position: 'relative',
			top: '-100px',
			visibility: 'hidden'
		}
	}
}));

interface IProps {
	title?: string,
	children: React.ReactNode,
	variant: 'normal' | 'contained',
	id?: string,
	color: StandardCSSProperties['backgroundColor']
}

const Section = (props: IProps) => {
	const classes = useStyles();

	return (
		<Box id={props.id} 
		// className={`${classes.root} ${props.variant === 'contained' ? classes.shaded : ''}`}
			sx={(theme: Theme) => ({
				pt: theme.spacing(4),
				pb: theme.spacing(4),
				bgcolor: props.variant === 'contained' ? props.color : 'transparent'
			})}
		>
			<Container>
				{props.title &&
					<Box display="flex" justifyContent="center" className={classes.header}>
						<Box>
							<Typography variant={'h1'}>{props.title}</Typography>
						</Box>
					</Box>
				}
				<Box>
					{props.children}
				</Box>
			</Container>
		</Box >
	);
}

Section.defaultProps = {
	variant: 'normal',
	color: colors.lightGrey
}

export { Section };