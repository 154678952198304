import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';


const useStyles = makeStyles((theme: Theme) => ({
	root: {
		marginTop: theme.spacing(2)
	},
	barContainer: {
		backgroundColor: theme.palette.primary.contrastText,
	},
	bar: {
		backgroundColor: theme.palette.primary.main
	}
}));

interface IProps {
	label: string,
	value: number,
	barHeight: number,
	className?: string
}

const Component = (props: IProps) => {
	const classes = useStyles();

	return (
		<Box className={`${classes.root} ${props.className}`}>
			<Typography>{props.label}</Typography>
			<Box
				className={classes.barContainer}
				display="flex"
				height={props.barHeight}
				width={1}>
				<Box
					className={classes.bar}
					width={props.value}
					height={props.barHeight}>
				</Box>
			</Box>
		</Box >
	);
}

Component.defaultProps = {
	label: '',
	value: 0,
	barHeight: 10
}

export default Component;