import React from 'react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Outlet } from 'react-router-dom';
import { Toolbar } from '@mui/material';

export const Main = () => {
	return (
		<>
			<Header />
			<Toolbar />
			<Outlet/>
			<Footer />
		</>);
}
