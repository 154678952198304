import React, { lazy, Suspense } from 'react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Section } from '../components/Section';
import { AboutMe } from './Main/AboutMe';
import { Skills } from './Main/Skills';
import Slogan from './Main/Slogan';
import { Projects } from './Main/Projects';
import { Availability } from './Main/Availability';
import { Box } from '@mui/material';
//const Projects = lazy(() => import('./Main/Projects').then(module => ({ default: module.Projects })));
const Education = lazy(() => import('./Main/Education'));

export const Home = () => {
	return (
		<>
			<Slogan />
			<Section id="about-me" title="about me">
				<AboutMe />
			</Section>
			<Section id="availability" title="Availability 2023">
				<Availability />
			</Section>
			<Section id="skills" title="skills" variant="contained">
				<Skills />
			</Section>
			<Suspense fallback={(<></>)}>
				<Section id="projects" title="projects">
					<Projects />
				</Section>
				<Section id="education" title="education" variant="contained">
					<Education />
				</Section>
			</Suspense>
		</>);
}
