import { Box, Button, Grid, Typography, Link } from '@mui/material';
import { ColorRange, getColorForPercentage } from '../../utils/colorUtils';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
}

export const Availability = (props: IProps) => {

  //const submitProjectUrl = '/submit-project';
  const submitProjectUrl = '#';
  const colorRanges: ColorRange[] = [
    //{ pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    //{ percentage: 0.0, color: { r: 0x8e, g: 0x8e, b: 0x8e } },
    { percentage: 0.0, color: { r: 0x1d, g: 0x1e, b: 0x22 } },
    //{ pct: 0.3, color: { r: 0xff, g: 0xa5, b: 0 } },
    //{ percentage: 1.0, color: { r: 0x00, g: 0xff, b: 0 } };
    { percentage: 0.6, color: { r: 0x2e, g: 0x7d, b: 32 } },
    { percentage: 1.0, color: { r: 0x2e, g: 0x7d, b: 32 } } 
  ];

  const MonthTile = (props: {
    to?: string,
    title: string,
    availability?: number
  }) => (
    <Grid item xs={3} sm={2} md={1}>
      {/* <RouterLink
        to={props.to ?? '#'}
        style={{textDecoration:'none'}}
      > */}
        <Box p={2} sx={{
          backgroundColor: getColorForPercentage(colorRanges, props.availability ?? 1),
          textAlign: 'center',
          color: 'white'
        }}>
          {props.title}
        </Box>
      {/* </RouterLink> */}
    </Grid>
  );

	return (
		<>
      <Typography>
        In the bar below you can check Sander's availability. Lucky for you, there is still some room for new projects. 
        Don't hesitate and submit your project now.
      </Typography>
      <Grid container spacing={0}>
        <MonthTile title="jan" availability={0} to={submitProjectUrl} />
        <MonthTile title="feb" availability={0} to={submitProjectUrl} />
        <MonthTile title="mar" availability={0} to={submitProjectUrl} />
        <MonthTile title="apr" availability={0} to={submitProjectUrl} />
        <MonthTile title="may" availability={0} to={submitProjectUrl} />
        <MonthTile title="jun" availability={0} to={submitProjectUrl} />
        <MonthTile title="jul" availability={0} to={submitProjectUrl} />
        <MonthTile title="aug" availability={.2} to={submitProjectUrl} />
        <MonthTile title="sep" availability={.9} to={submitProjectUrl} />
        <MonthTile title="okt" availability={.9} to={submitProjectUrl} />
        <MonthTile title="nov" availability={.9} to={submitProjectUrl} />
        <MonthTile title="dec" availability={.9} to={submitProjectUrl} />
      </Grid>
      <Box pt={2} display="flex" justifyContent="end">
        <Link href="mailto:sander.driesen@triact.net" style={{textDecoration:'none'}}>						
          <Button variant="contained" color="success">Get in touch</Button>
        </Link>
        {/* <Link to={submitProjectUrl} style={{textDecoration:'none'}}>
          <Button variant="contained" color="success">Submit project</Button>
        </Link> */}
      </Box>
    </>
	);
};
