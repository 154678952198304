import { Box, FormControl, FormControlLabel, FormLabel, Grid, FormGroup, Checkbox, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Section } from "../components/Section";
import { colors } from '../Theme';

export const SubmitProject = () => {

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Typography variant="h1">SUBMIT PROJECT</Typography>
      </Box>
      <Section id="Submit-project" title="Company">
        <Grid container spacing={2}>
            <Grid item sm={1} md={6}>
              <TextField label="Company name" fullWidth />
            </Grid>
            <Grid item sm={1} md={6}>
              <TextField label="Company Website" fullWidth />
            </Grid>
            <Grid item sm={1} md={6}>
              <TextField label="Company size" />
            </Grid>
        </Grid>
      </Section>

      <Section id="Submit-project" title="Contact" variant="contained" color={colors.lightBlue}>
        <Grid container spacing={2}>
          <Grid item sm={1} md={12}>
            <TextField label="Name" fullWidth />
          </Grid>
          <Grid item sm={1} md={12}>
            <TextField label="E-mail" fullWidth />
          </Grid>
          <Grid item sm={1} md={12}>
            <TextField label="Phone number" fullWidth />
          </Grid>
        </Grid>
      </Section>

      <Section id="Submit-project" title="Assignment">
        <Grid container spacing={2}>
          <Grid item sm={1} md={12}>
            <TextField label="Description" fullWidth />
          </Grid>
          <Grid item sm={1} md={12}>
            <Typography>Technologies</Typography>    
          </Grid>
          <Grid item sm={1} md={6}>
            <TextField label="Start date" fullWidth />
          </Grid>
          <Grid item sm={1} md={6}>
            <TextField label="Comment" fullWidth />
          </Grid>
          <Grid item sm={1} md={6}>
            <TextField label="Duration" fullWidth />
          </Grid>
        </Grid>
      </Section>

      <Section id="Submit-project" title="Location" variant="contained" color={colors.lightBlue}>
        <Grid container spacing={2}>
        <Grid item sm={1} md={12}>
            <TextField label="Location" fullWidth />
          </Grid>
          <Grid item sm={1} md={6}>
            <FormControl>
              {/* <FormLabel id="demo-row-radio-buttons-group-label">Location</FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="remote" control={<Radio />} label="Fully remote" />
                <FormControlLabel value="office" control={<Radio />} label="Fully at the office" />
                <FormControlLabel value="hybrid" control={<Radio />} label="Hybrid" />
              </RadioGroup>
            </FormControl>
          </Grid>          
          <Grid item sm={1} md={6}>
            <TextField label="Number of days at the office" fullWidth />
          </Grid>
        </Grid>
      </Section>

      <Section id="Submit-project" title="Tariff">
      <Grid container spacing={2}>
          <Grid item sm={1} md={12}>
            <TextField label="Min" fullWidth />
          </Grid>
          <Grid item sm={1} md={6}>
            <TextField label="Max" fullWidth />
          </Grid>
          <Grid item sm={1} md={12}>
            <FormControl>
              <Typography>Other covered expenses</Typography>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Travel expenses" />
                <FormControlLabel control={<Checkbox />} label="Lunch included at the office" />
                <FormControlLabel control={<Checkbox />} label="Free parking at the office" />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}