import { cyan } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const colors = {
	darkGrey: '#1D1E22',
	lightGrey: '#D4D4DC',
	white: '#fff',
	yellow: '#FEDA6A',
	blue: '#2B7BA6', //'#3797CB';
	lightBlue: cyan[600]
}

const theme = createTheme({
	palette: {
		primary: {
			//main: colorDarkGrey,
			//dark: colorDarkGrey,
			//light: colorLightGrey
			main: colors.blue,
			contrastText: colors.white
		},
		secondary: {
			main: colors.yellow,
			contrastText: colors.darkGrey
			//contrastText: accentColorYellow
		},
		text: {
			primary: colors.darkGrey,
			secondary: colors.lightGrey
		}		
	},
	typography: {
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		fontSize: 14,
		fontWeightLight: 100,
		fontWeightRegular: 300,
		fontWeightMedium: 400,
		h1: {
			fontSize: '2.125rem',
			fontWeight: 300,
			lineHeight: 1.235,
			letterSPacing: '0.00735em'
		},
		h2: {
			fontSize: '1.25rem',
			fontWeight: 400,
			lineHeight: 1.6,
			letterSPacing: '0.0075em'
		}
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					fontsize: '20px',
					backgroundColor: colors.darkGrey,
					boxShadow: 'none'
				}
			}
		}
	}
});

export default theme;