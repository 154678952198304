import { Typography } from '@mui/material';
import React from 'react';

export const AboutMe = () => {

	return (
		<>
			<Typography paragraph={true}>
				Looking for an experienced and versatile freelancer to tackle your next project? You've come to the right place!
			</Typography>
			<Typography paragraph={true}>
				Hello, I am Sander. As a passionate IT professional with expertise in software development, architectures, CI/CD, and AWS CloudNative, I'm here to deliver exceptional results for your assignments.
			</Typography>
			{/* <Typography paragraph={true}>
				I live in Rijswijk with my wife and two sons, in a beautiful house at the water. In my spare time I love to do some gardening, woodworking,
				go on a bike and spend time with my family. I have travelled around the world and I can’t wait until the boys are a bit older,
				so we can show them all the beautiful things the world has to offer. I am an enthusiastic person who works hard, but is always in for a joke.
			</Typography> */}
			<Typography paragraph={true}>
				With a track record of leading successful projects and seamlessly integrating applications, I take pride in bringing a "can do" mentality to every challenge. Fail fast, learn fast – that's how we grow!
			</Typography>
			{/* <Typography paragraph={true}>	
				I am passionate about technology, and how technology can be a business excellerator. I have a natural curiosity in architectural styles and extensive experience within distributed systems.
				I have a creative mindset if it comes to problem solving and determined to complete whatever job I do in the highest possible way.
			</Typography> */}
			{/* <Typography paragraph={true}>
				In my profile you can find more detailed information about my skills and a selection of projects I was involved in.  If you want more information, don't hesitate to
				contact me.
			</Typography> */}
			<Typography paragraph={true}>
				Whether you need innovative solutions, a fresh perspective, or a reliable partner to elevate your projects, I'm ready to make a difference. Reach out to me with your project details, and together, we'll create something remarkable.
			</Typography>
			<Typography paragraph={true}>
				Let's embark on a journey of success and make your vision a reality. Connect with me today and let's get started!
			</Typography>
		</>
	);
}