import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Project from "../models/Project";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
	},
	highlight: {
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(2)
	},
}));

interface IProps {
	project: Project;
	width?: number;
}

export const ProjectCard = (props: IProps) => {
	const classes = useStyles();

	const handleImageError = (e: any) => {
		e.target.onerror = null;
		// e.target.style.display = 'none'
		e.target.src = props.project.headerImageFallback;
	}

	return (
		<Card className={classes.root} elevation={12}>
			<CardActionArea>
				{props.project.headerImage && 
					<CardMedia
						component="img"
						src={props.project.headerImage}
						title={props.project.title}
						onError={handleImageError}
					/>
				}
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{props.project.title}
					</Typography>
					<Typography key={1} variant="body2" component="p">
						{props.project.summary}
					</Typography>
					<Box
						display="flex"
						flexWrap="wrap"
						justifyContent="flex-end">
						{props.project.highlights?.map((hl, i) =>
							<Chip key={i} label={hl} className={classes.highlight} color="primary" />
						)}
					</Box>
				</CardContent>
			</CardActionArea>
			{props.project.externalLink &&
				<CardActions>
					<Button size="small" color="primary">
						<Link href={props.project.externalLink} rel="">{props.project.externalLinkLabel}</Link>
					</Button>
				</CardActions>
			}
		</Card>
	)
}
