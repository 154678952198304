import { Link, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
//import { Link } from 'react-router-dom';

export const AvailableProjectCard = () => {
	const handleImageError = (e: any) => {
		e.target.onerror = null;
		 e.target.style.display = 'none'
		e.target.src = './content/assets/projects/your-project-here.jpg';
	}

	return (
		<Card elevation={12} sx={{
			boxShadow: '0px 0px 10px 10px rgba(254,218,106,1)'
			//boxShadow: '0px 0px 15px 15px rgba(43,123,166,1)'
		}}>
			<CardActionArea>
				<CardMedia
					component="img"
					src="./content/assets/projects/your-project-here.jpg"
					title="your project here"
					onError={handleImageError}
				/>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						Your project here?
					</Typography>
					<Typography key={1} variant="body2" component="p">
						Feel free to contact me to explore the possibilities and discuss how I can contribute to your project's success!
					</Typography>
					<Box mt={2}>
						<Link href="mailto:sander.driesen@triact.net" style={{textDecoration:'none'}}>						
							<Button variant="contained">Get in touch</Button>
						</Link>
						{/* <Link to="/submit-project" style={{textDecoration:'none'}}>
							<Button variant="contained">Submit project</Button>
						</Link> */}
					</Box>
				</CardContent>
			</CardActionArea>
		</Card>
	)
}
