import { Box, Container, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
	return ({
		root: {
			color: theme.palette.secondary.main,
			backgroundColor: theme.palette.text.primary,
			marginTop: '-80px' // to ignore the default app bar (with height 80) on top of every page.
		},
		slanted: {
			transform: 'skewY(-4deg)',
			position: 'relative',
			top:'-10px',
			zIndex: -1,
			overflow: 'hidden'
		},
		content: {
			position: 'relative',
			top: '100px',
			transform: 'skewY(4deg)',
			height: '380px',
			width: '100%'
		},
		container: {
			height: '100%',
			//paddingTop: '20px'
		},
		image: {
			//backgroundImage: 'url(./content/assets/sander.png)',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'auto 340px',
			backgroundPosition: 'left 10% bottom'
		},
		slogan: {
			fontSize: '43px',
			fontWeight: 100,
			color: theme.palette.secondary.main,
			[theme.breakpoints.down('md')]: {
				fontSize: '35px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '30px',
				textAlign: 'right'
			}
		}
	});
});

interface IProps {
	imagePosition: 'left' | 'right';
}

const Slogan = (props: IProps) => {
	const classes = useStyles();
	return (
		<Box className={`${classes.root} ${classes.slanted}`}>
			<Box className={`${classes.content}`}>
				<Container disableGutters>
					<Box style={{ position: 'absolute' }}>
						<picture>
							<source srcSet="content/assets/sander1.webp" type="image/webp" />
							<img src="content/assets/sande1.png" alt="sander" height={400} style={{ position: 'relative', left: '10%' }} />
						</picture>
					</Box>
				</Container>
				<Container style={{ height: '100%', paddingBottom: '100px' }}>
					<Box style={{ position: 'relative', height: '100%' }}>
						<Grid container direction="row-reverse" alignItems="center" style={{ height: '100%' }}>
							<Grid item xs={6} sm={6} md={8} lg={8}>
									{/* <span className={classes.slogan}>
										<strong>Today</strong> is <br />
										the <strong>IMPROVED</strong> version <br />
										of yesterday
									</span> */}
									<span className={classes.slogan}>
										Fail fast, <br />
										<strong>LEARN</strong><br />
										faster
									</span>
							</Grid>
						</Grid>
					</Box>
				</Container>

				{/* <Container className={`${classes.container} ${classes.image}`} disableGutters>

						<Container className={classes.container}>
						<Grid container justify="flex-end" style={{ height: '100%' }}>
							<Grid item xs={12} sm={7} md={8} lg={8}>
								<Typography className={classes.slogan}>
									<strong>Today</strong> is <br />
									the <strong>IMPROVED</strong> version <br />
									of yesterday
								</Typography>
							</Grid>
						</Grid>
						</Container>
					</Container> */}
			</Box>
		</Box>)
}

Slogan.defaultProps = {
	imagePosition: 'right'
}

export default Slogan;