import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'mobx-react';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Main } from './scenes/Main';
import { Home } from './scenes/Home';
import { SubmitProject } from './scenes/SubmitProject';
import RootStore from './stores/RootStore';
import theme from './Theme';

const rootStore = new RootStore();

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Provider rootStore={rootStore}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Main />}>
							<Route index element={<Home />} />
							<Route path="submit-project" element={<SubmitProject />} />
						</Route>
						<Route
							path="*"
							element={
								<main style={{ padding: "1rem" }}>
									<p>Oops! This page does not exist.</p>
								</main>
							}
						/>
					</Routes>				
				</BrowserRouter>
			</Provider>
		</ThemeProvider>
	);
}

export default App;
