import { Box, Theme, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Skill from '../../components/Skill';

const useStyles = makeStyles((theme: Theme) => ({
	rowHeader: {
		marginTop: theme.spacing(4),
	}
}));

export const Skills = () => {
	const classes = useStyles();
	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6} md={3}>
					<Typography variant="h2">Architectural Styles</Typography>
					<Skill label="Distributed Systems" value={1} />
					<Skill label="Cloud Native & Serverless" value={.8} />
					<Skill label="SOA" value={.9} />
					<Skill label="Messaging Patterns" value={1} />
					<Skill label="Domain Driven Design" value={.8} />
					<Skill label="CQRS" value={.9} />
					<Skill label="Event Sourcing" value={.8} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Typography variant="h2">Coding &amp; Frameworks</Typography>
					<Skill label="C#" value={1} />
					<Skill label="ASP.NET Core" value={1} />
					<Skill label="JavaScript / node.js / TypeScript" value={.9} />
					<Skill label="React / MobX" value={.8} />
					<Skill label="Svelte" value={.8} />
					<Skill label="Vue" value={.3} />
					<Skill label="Scripting Languages" value={.6} />
					<Skill label="NServiceBus" value={.9} />
					<Skill label="Entity Framework" value={.4} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Typography variant="h2">Data</Typography>
					<Skill label="DynamoDB" value={.9} />
					<Skill label="MongoDB" value={.9} />
					<Skill label="Redshift" value={.8} />
					<Skill label="SQL" value={.9} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Typography variant="h2">CI/CD</Typography>
					<Skill label="Azure DevOps" value={.8} />
					<Skill label="GitLab" value={.9} />
					<Skill label="Octopus Deploy" value={1} />
					<Skill label="AWS CloudFormation / CDK" value={1} />
				</Grid>
			</Grid>
			<Box className={classes.rowHeader}>
				<Typography variant="h2">Amazon Web Services</Typography>
			</Box>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6} md={3}>
					<Skill label="CloudFormation / CDK" value={1} />
					<Skill label="VPC" value={1} />
					<Skill label="EC2" value={1} />
					<Skill label="Route 53" value={1} />
					<Skill label="Transit Gateway" value={.6} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Skill label="Cognito" value={.6} />
					<Skill label="Elastic Load Balancing" value={1} />
					<Skill label="API Gateway" value={.3} />
					<Skill label="CloudFront" value={1} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Skill label="S3" value={.9} />
					<Skill label="DynamoDB" value={.9} />
					<Skill label="Aurora" value={.8} />
					<Skill label="RDS" value={1} />
					<Skill label="Redshift" value={.8} />
					<Skill label="QuickSight" value={.6} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Skill label="SNS" value={1} />
					<Skill label="SQS" value={1} />
					<Skill label="Lambda" value={1} />
					<Skill label="Kinesis" value={.8} />					
					<Skill label="Simple Email Service" value={.5} />
				</Grid>
			</Grid>
			<Box className={classes.rowHeader}>
				<Typography variant="h2">Azure</Typography>
			</Box>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6} md={3}>
					<Skill label="DevOps" value={1} />
					<Skill label="VNet" value={.5} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Skill label="Virtual Machines" value={.9} />
					<Skill label="App Services" value={.5} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Skill label="Table Storage" value={.9} />
					<Skill label="Azure SQL" value={.9} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Skill label="Active Directory" value={.5} />
					<Skill label="Service Bus" value={.6} />
				</Grid>
			</Grid>
		</>
	);
}