import { Box, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GitHub, LinkedIn, MailOutline, Twitter } from '@mui/icons-material';
import React from 'react';


const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.text.primary,
		color: theme.palette.text.secondary,
		height: '200px'
	},
	link: {
		margin: theme.spacing(2)
	}
}));

export const Footer = (props: {}) => {
	let classes = useStyles();

	return (
		<Box display="flex" alignItems="center" justifyContent="center" className={classes.root}>
			<Box className={classes.link}>
				<Link href="https://www.linkedin.com/in/sander-driesen" color="textSecondary" aria-label="LinkedIn">
					<LinkedIn />
				</Link>
			</Box>
			<Box className={classes.link}>
				<Link href="https://github.com/sanderdriesen" color="textSecondary" aria-label="GitHub">
					<GitHub />
				</Link>
			</Box>
			<Box className={classes.link}>
				<Link href="https://twitter.com/sanderdriesen" color="textSecondary" aria-label="Twitter">
					<Twitter />
				</Link>
			</Box>
			<Box className={classes.link}>
				<Link href="mailto:sander.driesen@triact.net" color="textSecondary" aria-label="email">
					<MailOutline />
				</Link>
			</Box>
		</Box>
	);
}
