import { useMediaQuery, useTheme } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ProjectCard } from '../../components/ProjectCard';
import { AvailableProjectCard } from '../../components/AvailableProjectCard';
import RootStore from '../../stores/RootStore';
import Project from '../../models/Project';

interface IProps {
	rootStore?: RootStore
}

export const Projects = inject('rootStore')((props: IProps) => {
	const theme = useTheme();
	const one = useMediaQuery(theme.breakpoints.down('sm'));
	const two = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const [projects, setProjects] = useState<Project[]>([]);
	
	useEffect(() => {
		(async () => {
			const data = await props.rootStore?.projectStore.loadProjects();
			if (data) {
				setProjects(data);
			}
		})();
	}, [props.rootStore?.projectStore]);

	return (
		<Masonry columns={one ? 1 : (two ? 2 : 3)} spacing={4}>
			<>
				<AvailableProjectCard />
				{projects.map((project) => 
					<ProjectCard key={project.id} project={project} />
				)}
			</>
		</Masonry>
	);
});