
export type ColorRange = {
  percentage: number,
  color: {
    r: number,
    g: number,
    b: number
  }
};

/**
 * Calculates color based on percentage using a predefined color range.
 * @param colorRanges
 * const percentColors = [
 *  { percentage: 0.0, color: { r: 0x8e, g: 0x8e, b: 0x8e } },
 *  { percentage: 0.5, color: { r: 0xff, g: 0xa5, b: 0 } },
 *  { percentage: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ];
 * @param percentage
 * @returns 
 */
export function getColorForPercentage(colorRanges: ColorRange[], percentage: number) {
  for (var i = 1; i < colorRanges.length - 1; i++) {
      if (percentage < colorRanges[i].percentage) {
          break;
      }
  }
  var lower = colorRanges[i - 1];
  var upper = colorRanges[i];
  var range = upper.percentage - lower.percentage;
  var rangePct = (percentage - lower.percentage) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  // or output as hex if preferred
};