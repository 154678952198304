import { AppBar, Box, Container, Drawer, Hidden, IconButton, MenuItem, Theme, Toolbar, Typography } from '@mui/material';
import { Menu, Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

const backgroundColor = `rgba(29, 30, 34, 1)`;

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: backgroundColor,
		color: theme.palette.text.secondary,
		fontSize: '20px',
		zIndex: 10,
		boxShadow: 'none'
	},
	toolbar: { 
		minHeight: '80px'
	},
	logo: {
		height: '50px'
	},
	toolbarTitle: {
		flex: 1,
	},
	toolbarLink: {
		padding: theme.spacing(3),
		flexShrink: 0,
		cursor: 'pointer',
		fontSize: '16px',
		color: theme.palette.text.secondary,
	},
	menuButton: {
	},
	drawer: {
	},
	drawerPaper: {
		width: '80%',
		backgroundColor: backgroundColor,
		color: theme.palette.text.secondary,
	},
	drawerHeader: {
		borderBottom: 'solid 1px',
		borderColor: theme.palette.primary.light,
	},
	drawerMenuItem: {

	}
}));


export const Header = observer((props: {}) => {
	const classes = useStyles();
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);

	const menuItems = [{
		label: 'about me',
		to: 'about-me'
	}, {
		label: 'availability',
		to: 'availability'
	}, {
		label: 'skills',
		to: 'skills'
	}, {
		label: 'projects',
		to: 'projects'
	}, {
		label: 'education',
		to: 'education'
	}];

	const onMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget as any);
	};

	const handleClose = () => {
		setMenuAnchorEl(null);
	}

	return (<>
		<AppBar>
			<Container>
				<Toolbar className={classes.toolbar} disableGutters>
					<RouterLink to="/" style={{height: 50}}>
						<picture>
							<source srcSet="content/assets/logo.webp" type="image/webp" />
							<img src="content/assets/logo.png" alt="Sander Driesen" height={50} />
						</picture>
					</RouterLink>
					<Typography className={classes.toolbarTitle} component="h2"></Typography>
					<Hidden xsDown>
						{menuItems.map((item, i) =>
							<Link key={i} className={classes.toolbarLink} color="inherit"
								to={item.to}
								offset={-80}
								smooth={true}>
								{item.label}
							</Link>
						)}
					</Hidden>
					<Hidden smUp>
						<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={onMenuButtonClick}>
							<Menu />
						</IconButton>
					</Hidden>

					<Drawer
						anchor="right"
						open={Boolean(menuAnchorEl)}
						onClose={handleClose}
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper
						}}>
						<Box className={classes.drawerHeader}>
							<Container>
								<Toolbar className={classes.toolbar} disableGutters>
									<Typography className={classes.toolbarTitle} component="h2"></Typography>
									<IconButton edge="start" onClick={handleClose} color="inherit">
										<Close />
									</IconButton>
								</Toolbar>
							</Container>
						</Box>
						{menuItems.map((item, i) =>
							<MenuItem key={i} onClick={handleClose}>
								<Link className={classes.drawerMenuItem} color="inherit"
									to={item.to}
									offset={-80}
									smooth={true}
									onClick={handleClose}>
									{item.label}
								</Link>
							</MenuItem>
						)}
					</Drawer>
				</Toolbar>
			</Container>
		</AppBar>
	</>);
});
