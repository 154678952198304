import { action } from 'mobx';
import Project from "../models/Project";

export default class ProjectStore {

	@action async loadProjects() : Promise<Project[]> {
		return [{
			id: 14,
			title: 'Blis Digital',
			summary: `As the Lead Developer for accreditation tooling, I take pride in spearheading the technical vision and execution of this essential platform. My role involves overseeing the development team, ensuring impeccable code quality, and aligning the tooling with the accreditation standards. By driving innovation and continuous improvement, I empower our team to deliver a robust and user-friendly solution that streamlines the accreditation process, enabling our clients to achieve their business objectives with greater efficiency and confidence. Together, we embrace the challenge of creating cutting-edge tooling that upholds the customer's commitment to excellence and elevates our clients' experiences to new heights.`,
			highlights: ['C#', 'ASP.Net', 'Vue', 'Azure'],
			headerImage: './content/assets/projects/14/header1.webp',
			headerImageFallback: './content/assets/projects/14/header1.jpg',
			externalLinkLabel: 'visit Blis Digital',
			externalLink: 'https://blisdigital.nl'
		}, {
			id: 12,
			title: 'RailApp',
			summary: 'Fulfilling the role as ad interim CTO.' +
				'RailApp is an online planning application and a hire and secondment platform for rail services and rail professionals at the same time. ' +
				'When assigning a job, qualifications/certifications will be checked ensuring that only 100% qualified professionals can be assigned.',
			highlights: ['CTO a.i.'],
			headerImage: './content/assets/projects/12/header.webp',
			headerImageFallback: './content/assets/projects/12/header.jpg',
			externalLinkLabel: 'visit RailApp',
			externalLink: 'https://railapp.nl'
		}, {
			id: 13,
			title: 'PrimeVision (PostNL)',
			summary: 'Migration of the existing Sorting Decision System into a AWS Serverless solution. ' +
				'All PostNL Parcel sorting centers will be migrated to this system eventually.',
			highlights: ['AWS Serverless', 'GitLab', 'CI/CD', 'TypeScript'],
			headerImage: './content/assets/projects/13/header.webp',
			headerImageFallback: './content/assets/projects/13/header.jpg',
			externalLinkLabel: 'visit PrimeVision',
			externalLink: 'https://www.primevision.com/sorting-decision-systems/'
		}, {
			id: 1,
			title: 'Tijdprikker.nl',
			summary: 'Create an event and divide the event in timeblocks. Invitees can select a timeblock of their choice. Per timeblock a limited number of ' +
				'seats is available.',
			highlights: ['Co-founder', 'React', 'AWS CloudFront', 'MongoDB Atlas', 'node'],
			headerImage: './content/assets/projects/1/header.webp',
			headerImageFallback: './content/assets/projects/1/header.jpg',
			externalLinkLabel: 'Visit Tijdprikker >',
			externalLink: 'https://tijdprikker.nl',
		}, {
			id: 6,
			title: 'Cloud Migration',
			summary: 'Migrate from an on-premise environment to AWS. In multiple phases the infrastructure, databases and software was migrated with minimal downtime.',
			highlights: ['AWS CloudFormation', 'AWS VPC', 'AWS RDS'],
			headerImage: './content/assets/projects/6/header.webp',
			headerImageFallback: './content/assets/projects/6/header.jpg',
			externalLinkLabel: undefined,
			externalLink: undefined,
		}, {
			id: 2,
			title: 'Click-2-call',
			summary: 'Click-to-call based on WebRTC technology. Via the browser a voice connection is established with a call center agent like ' +
				'any traditional telephone call.',
			highlights: ['WebRTC', 'React', 'C#', 'AWS CloudFront'],
			headerImage: './content/assets/projects/2/header.webp',
			headerImageFallback: './content/assets/projects/2/header.jpg',
			externalLinkLabel: 'visit talk',
			externalLink: 'https://www.soundofdata.com/talk/',
		}, {
			id: 3,
			title: 'Junior Eurovision Song Contest Voting System',
			summary: 'Creating a voting system for the European Broadcast Union in which 16 countries participate simultaniously in a voting window ' +
				'of 15 minutes. The system calculates the results near realtime according the regulations.',
			highlights: ['C#', 'NServiceBus', 'Azure', 'Redis'],
			headerImage: './content/assets/projects/3/header.webp',
			headerImageFallback: './content/assets/projects/3/header.jpg',
			externalLinkLabel: undefined,
			externalLink: undefined,
		}, {
			id: 11,
			title: 'Data Warehousing',
			summary: 'Created an ETL to move and normalize data from different systems into a central Data Warehousing data store.',
			highlights: ['C#', 'AWS Redshift', 'AWS Kinesis'],
			headerImage: './content/assets/projects/11/header.webp',
			headerImageFallback: './content/assets/projects/11/header.jpg',
			externalLinkLabel: undefined,
			externalLink: undefined,
		}, {
			id: 4,
			title: 'Integrated QuickSight dashboards.',
			summary: 'Amazon QuickSight, does it ring a bell? It might if your job involves business intelligence and delivering insight in your organization. ' +
				'QuickSight lets you easily create and publish interactive dashboards. We have incorporate it in Gearbox to provide embedded call data ' +
				'analytics to out customers.',
			highlights: ['AWS QuickSight', 'C#', 'JavaScript', 'AWS Redshift'],
			headerImage: './content/assets/projects/4/header.webp',
			headerImageFallback: './content/assets/projects/4/header.jpg',
			externalLinkLabel: undefined,
			externalLink: undefined,
		}, {
			id: 5,
			title: 'Gearbox',
			summary: 'Integration platform for multiple voice interaction systems. Gearbox deliveres standardized voice solutions composed of multiple other ' +
				'voice systems.',
			highlights: ['Distributed Systems', 'C#', 'NServiceBus', 'ASP.Net Core', 'MongoDB', 'SOA'],
			headerImage: './content/assets/projects/5/header.webp',
			headerImageFallback: './content/assets/projects/5/header.jpg',
			externalLinkLabel: 'visit sound of data',
			externalLink: 'https://soundofdata.com',
		}, {
			id: 7,
			title: 'IAM with Auth0',
			summary: 'Identity and access management implemented in an portal and APIs using Auth0.',
			highlights: ['Auth0', 'JWT', 'OpenID Connect', 'OAuth2'],
			headerImage: './content/assets/projects/7/header.webp',
			headerImageFallback: './content/assets/projects/7/header.jpg',
			externalLinkLabel: undefined,
			externalLink: undefined,
		}, {
			id: 8,
			title: 'Plug & Ride',
			summary: 'Making private charging stations, used by electric vehicles, available to the public. Charging station owners can register their ' +
				'stations with Plug and Ride and EV-drivers can make a reservation.',
			highlights: ['Azure', 'NServiceBus', 'Azure Table Storage'],
			headerImage: './content/assets/projects/8/header.jpg',
			headerImageFallback: './content/assets/projects/8/header.webp',
			externalLinkLabel: undefined,
			externalLink: undefined,
		}, {
			id: 10,
			title: 'Lavart.nl Wordpress Webshop',
			summary: 'Creation of a wordpress webshop for unique hand made jewelry.',
			highlights: ['Wordpress', 'Ubuntu', 'Azure SQL Database'],
			headerImage: './content/assets/projects/10/header.webp',
			headerImageFallback: './content/assets/projects/10/header.jpg',
			externalLinkLabel: 'Visit Lavart >',
			externalLink: 'https://lavart.nl',
		}] as Project[];
	}
}